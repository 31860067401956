<template>
  <div id="exportDialog">
    <el-dialog
      title="导出报表"
      :visible.sync="show"
      width="500px"
      @closed="reset"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="时间范围" prop="time">
          <el-date-picker
            v-model="form.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="车牌号">
          <el-input placeholder="请输入车牌号" v-model="form.plateNum">
          </el-input> -->
        <!-- </el-form-item> -->
        <el-form-item label="停车场">
          <el-select v-model="form.parkingLotId" placeholder="请选择停车场">
            <el-option
              v-for="item in parkingLotList"
              :key="item.parkingLotId"
              :label="item.parkingLotName"
              :value="item.parkingLotId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批状态">
          <el-select v-model="form.refundStatus" placeholder="请选择审批状态">
            <el-option
              v-for="item of refundStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
      refundStatusList: [
        {
          label: "申请退款中",
          value: 0,
        },
        {
          label: "已同意",
          value: 1,
        },
        {
          label: "已拒绝",
          value: 2,
        },
      ],
      form: {
        time: "",
        parkingLotId: "",
        refundStatus: "",
        plateNum: "",
      },
      rules: {
        time: [{ required: true, validator: this.checkTime, trigger: "blur" }],
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  methods: {
    checkTime(rule, value, callback) {
      for (let item of value) {
        if (item === "") {
          callback(new Error("请选择时间范围"));
          return;
        }
      }
      callback();
    },
    reset() {
      this.$refs["form"].resetFields();
    },
    confirm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let startTime = this.form.time[0];
          let endTime = this.form.time[1];
          try {
            await this.$http.post(
              "/order/refund/export",
              {
                startTime,
                endTime,
                parkingLotId: this.form.parkingLotId,
                refundStatus: this.form.refundStatus,
                // plateNum: this.form.plateNum,
              },
              {
                responseType: "blob",
                timeout: 0,
              }
            );
            this.loading = false;
          } catch {
            this.$message.error("导出失败");
            this.loading = false;
          }
        }
      });
    },
    handleShow() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
#exportDialog {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
